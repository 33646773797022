
<template>
  <CRow>
    <CCol col="12" xl="12">
         <CCard class="cards">
        <CCardHeader>
          <CRow>
            <CCol class="styleHeader" col="3">
                {{$t('message.paymentInvoices')}}
            </CCol>
             <CCol col="9" style="text-align: left;" class="cardButton">
                <CButton class="first"  to="payments/create"  square color="primary">
                    <CIcon class="c-icon" name="cil-chevron-top" /> &emsp;  {{$t('message.createPaymentInvoices')}} || {{$t('message.customer')}}
                </CButton>
                  <CButton  to="payments/create-office-payment"  square color="primary">
                    <CIcon class="c-icon" name="cil-chevron-top" /> &emsp;  {{$t('message.createPaymentInvoices')}} || {{$t('message.office')}}
                </CButton>
            </CCol>
        </CRow>
        </CCardHeader>
        <CCardBody>
          <!-- Using the VdtnetTable component -->
          <vdtnet-table
            ref="table"
            :fields="fields"
            :opts="options"
            @edit="doAlertEdit"
            @show="doAlertShow"
            @delete="doAlertDelete"
            @reverseStatus ='doAlertReverse'
            @status='doAlertReverse'
            @reloaded="doAfterReload"
            @table-creating="doCreating"
            @table-created="doCreated"
          >
          </vdtnet-table>
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
</template>

<script>
import swal from 'sweetalert'
import VdtnetTable from '../VdtnetTable.vue'
export default {
  name: 'SalesInvoices',
  components: { VdtnetTable },
  data () {
    return {
      options: {
        ajax: {
          beforeSend: function (xhr) {
            xhr.setRequestHeader(
              'Authorization',
              'Bearer ' + localStorage.token
            )
          },
          url: this.$hostUrl + 'sale-invoices',
          dataSrc: (json) => {
            return json.data
          }
        },
        buttons: [
          {
            extend: 'print',
            text: this.$t('message.print'),
            titleAttr: '',
            className: 'print',
            exportOptions: {
              columns: 'th:not(:last-child)'
            }
          },
          {
            extend: 'copy',
            text: this.$t('message.copy'),
            className: 'copy',
            titleAttr: ''
          },
          {
            extend: 'excel',
            text: '',
            titleAttr: 'إكسل',
            className: 'fa fa-table btn-success',
            exportOptions: {
              columns: ':visible(:not(.not-export-col))'
            }
          }
        ],
        dom:
          "<'row'<'col-sm-6 col-md-6'f><'col-sm-6 col-md-6 added'l>>" +
          "<'row'<'col-sm-12'Btr>>" +
          "<'row'<'col-sm-12 col-md-5'p><'col-sm-12 col-md-7'i>>",
        paging: true,
        language: {
          url: this.$t('message.url')
        },
        responsive: false,
        processing: true,
        searching: true,
        searchDelay: 1500,
        destroy: true,
        ordering: true,
        lengthChange: true,
        serverSide: false,
        fixedHeader: true,
        saveState: true,
        order: [6, 'DESC'],
        select: {
          style: 'multi'
        }
      },
      fields: {
        id: {
          label: this.$t('message.invoicesId'),
          sortable: true,
          searchable: true,
          defaultOrder: 'SORT_ASC '
        },
        price_paid: {
          label: this.$t('message.invoiceTotal'),
          sortable: false,
          searchable: true,
          defaultOrder: 'SORT_ASC '
        },
        name: {
          label: this.$t('message.customer'),
          sortable: false,
          searchable: true,
          defaultOrder: 'SORT_ASC '
        },
        office: {
          label: this.$t('message.office'),
          sortable: false,
          searchable: true,
          defaultOrder: 'SORT_ASC '
        },
         office: {
          label: this.$t('message.office'),
          sortable: false,
          searchable: true,
          defaultOrder: 'SORT_ASC '
        },
         status_invoice: {
          label: 'الحالة',
          sortable: false,
          searchable: true,
          defaultOrder: 'SORT_ASC '
        },
         status: {
          label: 'تغيير',
          sortable: false,
          searchable: true,
          defaultOrder: 'SORT_ASC '
        },
        created_at: {
          label: this.$t('message.CreatedAt'),
          sortable: false,
          searchable: true,
          defaultOrder: 'SORT_ASC '
        },
        actions: {
          isLocal: true,
          label: this.$t('message.operations'),
          defaultContent: '<button href="javascript:void(0);" data-action="show" class="btn btn-info btn-sm" title="عرض الطلب"><i class="mdi mdi-square-show-outline"></i>' + this.$t('message.show') + ' </button>' +
            '&ensp;<button href="javascript:void(0);" data-action="edit" class="btn btn-dark btn-sm"><i class="mdi mdi-square-edit-outline"></i>' + this.$t('message.update') + ' </button>' +
          '&ensp;<span data-action="delete" class="btn btn-danger btn-sm"><i class="mdi mdi-delete" @click="swal"></i>' + this.$t('message.delete') + ' </span>'
        }
      },
      quickSearch: '',
      details: {}
    }
  },
  methods: {
    doLoadTable (cb) {
      this.$http.getJSON(
        this.$hostUrl + 'sale-invoices',
        function (data) {
          cb(data.data)
        }
      )
    },
    doAlertShow (data) {
      this.$router.push({ path: `/payments/${data.id}/show`, params: { id: data.id } })
    },
    doAlertEdit (data) {
      this.$router.push({
        path: `/payments/${data.id}/edit`,
        params: { id: data.id }
      })
    },

    doAlertDelete (data, row, tr, target) {
      console.log(`deleting item ID: ${data.id}`)

      swal({
        title: 'هل انت متأكد ؟',
        text: 'بمجرد الحذف ، لن تتمكن من استرداد هذا العنصر !',
        icon: 'warning',
        buttons: ['لا', 'نعم'],
        dangerMode: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33'
      }).then((willDelete) => {
        if (willDelete) {
          this.$http
            .delete(
              `${this.$hostUrl}sale-invoices/${data.id}`,
              { _method: 'delete' }
            )
            .then((response) => {
              swal({ title: response.data.message, buttons: 'تم' })
            })
          tr.remove()
          const table = this.$refs.table
          setTimeout(() => {
            table.reload()
          }, 1500)
        } else {
          swal({ title: ' تم الغاء الحذف !', buttons: 'تم' })
        }
      })
    },
     doAlertReverse (data, row, tr, target) {
      console.log(`deleting item ID: ${data.id}`)

      swal({
        title: 'هل انت متأكد ؟',
        text: 'بمجرد التأكيد ، سيتم تغيير حالة الفاتورة !',
        icon: 'warning',
        buttons: ['لا', 'نعم'],
        dangerMode: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33'
      }).then((willDelete) => {
        if (willDelete) {
          this.$http
            .post(
              `${this.$hostUrl}sale-invoices/${data.id}/status`,
              { _method: 'patch' }
            )
            .then((response) => {
              swal({ title: response.data.message, buttons: 'تم' })
            })
          tr.remove()
          const table = this.$refs.table
          setTimeout(() => {
            table.reload()
          }, 1500)
        } else {
          swal({ title: ' تم الإلغاء !', buttons: 'تم' })
        }
      })
    },
    doAfterReload (data, table) {
      console.log('data reloaded')
    },
    doCreating (comp, el) {
      console.log('creating')
    },
    doCreated (comp) {
      console.log('created')
    },
    doSearch () {
      this.$refs.table.search(this.quickSearch)
    },
    doExport (type) {
      const parms = this.$refs.table.getServerParams()
      parms.export = type
    },
    formatCode (zipcode) {
      return zipcode.split('-')[0]
    }
  }
}
</script>
<style scoped>
.payment-list{
  background-color: #4f5d73 !important;
  border-color: #4f5d73 !important;
  color:#fff;
     padding-left: 8px;
    padding-right: 10px;
}
.first {
      margin-left: 22px;
      margin-right:22px;
}
</style>
